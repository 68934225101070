// extracted by mini-css-extract-plugin
export var column = "FormTags__column__Q9fHt";
export var flex = "FormTags__flex__A5yyj";
export var flexColumn = "FormTags__flexColumn__zj7xQ";
export var gap1 = "FormTags__gap1__RjR6e";
export var gap2 = "FormTags__gap2__h_NQB";
export var gap3 = "FormTags__gap3__Y3xmz";
export var gap4 = "FormTags__gap4__IbVce";
export var gap5 = "FormTags__gap5__iTKOv";
export var row = "FormTags__row__sqxfA";
export var tag = "FormTags__tag__seXSz";
export var tags = "FormTags__tags__YjSC6";